import authApi from "@/api/authApi"
import delmondApi from "@/api/delmondApi";

export const singInUser = async ({commit}, user) =>{
    const {email, password} = user
    try {
        const {data} = await authApi.post('auth/loginByEmail',{email, password })
        const { idToken, user }  = data;
        commit('loginUser', {user, idToken})
        return { ok: true, message: (user.first_name).toUpperCase() }
    } catch (error) {
        // return error;
        return { ok: false, message: error.response.data.message }
        
    }
}

export const checkAuthentication = async ({commit}) =>{
    const idToken = await localStorage.getItem('idToken')
    
   if(!idToken){
       commit('logout')
       return {ok:false, message: 'no hay token'}
   }
   
   try {
    
    const {data} = await delmondApi.get('auth/data-user')
    const {name, email, image, id, signature } = data;
    commit('loginUser', {user: {email, name, image, id, signature},idToken})
    return { ok: true, message: 'ingreso exitoso' }   
       
   } catch (error) {
    commit('logout')
    return {ok:false, message: error.response.data.message}
       
   }
}

export const updateSignature = async ({  commit }, signature ) => {
    try {
        const {data} = await delmondApi.post(`delivery/updateSignature`,signature)
        if(data.affectedRows>0){
            commit('changeSignature', signature.signature)
            return true
        } 

        return false;
    } catch (error) {
        console.log(error);
        throw error;
    }
 }